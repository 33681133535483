/* eslint-disable @next/next/no-img-element */
export const KhietBongStage = (): JSX.Element => {
  return (
    <div className="fixed z-20 w-64 bottom-5 left-5 min-w-max">
      <div className="absolute w-52">
        {/* <img
          src="/images/bubble.png"
          alt="ga-khiet-bong"
          className="absolute w-52 -top-28 -right-20"
          // height="auto"
        />
        <div className="absolute -top-20 -right-10">
          Hãy đăng ký <br /> để nhận <br /> 3 tô miễn phí
        </div> */}

        {/* <img
          src="/images/ga/5-thuthach6ngay.png"
          alt="ga-khiet-bong"
          className="absolute w-52 -top-40"
          // height="auto"
        /> */}
      </div>
      {/* <img
        src="/images/ga/gadihoc.png"
        alt="ga-khiet-bong"
        className="w-32"
        height="auto"
      /> */}
    </div>
  );
};
