import axios from "axios";
import mixpanel from "mixpanel-browser";
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY ?? "", {
  debug: process.env.NODE_ENV === "development",
});

const sendServerAnalytics = async (
  event_name: string,
  feature: string,
  data: any
) => {
  try {
    await axios.post("/analytic/v1/track", {
      event_name,
      feature,
      data,
    });
  } catch (error) {}
};

export { mixpanel, sendServerAnalytics };
