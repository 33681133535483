import { XIcon } from "@heroicons/react/outline";
import axios from "axios";
import { LoadingContainer } from "components/LoadingContainer";
import { notify } from "components/Notification";
import { useState } from "react";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

const SITE_KEY = process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY;

const FeedbackForm = ({ token }: any) => {
  const [type, setType] = useState<"BUG" | "FEATURE" | "OTHER">("FEATURE");
  const [content, setContent] = useState<string>("");
  const [file, setFile] = useState<File[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmitFeedback = async () => {
    console.log({
      token,
      file,
      type,
      content,
    });

    setLoading(true);

    const body: FormData = new FormData();

    body.append("token", token);
    body.append("feedback_type", type);
    body.append("content", content);

    if ((file ?? [])?.length > 0) {
      body.append("file", (file as any)[0]);
    }

    try {
      await axios.post("/api/v1/feedback", body, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      notify({
        content: "Cảm ơn bạn đã góp ý cho hệ thống",
      });

      setContent("");
      setFile([]);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  return (
    <LoadingContainer spinning={loading}>
      <div className="p-3">
        <div className="flex flex-col space-y-2">
          <div>Hãy cho Gà Khiết Bông biết bạn đang muốn góp ý về:</div>
          <div className="flex items-center">
            <input
              id="bug"
              name="notification-method"
              type="radio"
              defaultChecked={type === "BUG"}
              onChange={(e) => {
                if (e.target.checked) {
                  setType("BUG");
                }
              }}
              className="w-4 h-4 border-gray-300 text-primary-pink focus:ring-primary-pink"
            />
            <label
              htmlFor="bug"
              className="block ml-3 text-sm font-medium text-gray-700"
            >
              Lỗi hệ thống
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="feature"
              name="notification-method"
              type="radio"
              defaultChecked={type === "FEATURE"}
              onChange={(e) => {
                if (e.target.checked) {
                  setType("FEATURE");
                }
              }}
              className="w-4 h-4 border-gray-300 text-primary-pink focus:ring-primary-pink"
            />
            <label
              htmlFor="feature"
              className="block ml-3 text-sm font-medium text-gray-700"
            >
              Tính năng mới
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="feature"
              name="notification-method"
              type="radio"
              defaultChecked={type === "OTHER"}
              onChange={(e) => {
                if (e.target.checked) {
                  setType("OTHER");
                }
              }}
              className="w-4 h-4 border-gray-300 text-primary-pink focus:ring-primary-pink"
            />
            <label
              htmlFor="feature"
              className="block ml-3 text-sm font-medium text-gray-700"
            >
              Khác
            </label>
          </div>
        </div>
        <div>
          <textarea
            rows={4}
            name="comment"
            id="comment"
            className="block w-full mt-2 border-gray-300 rounded-md shadow-sm focus:ring-primary-pink focus:border-primary-pink sm:text-sm"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <div>Tệp đính kèm</div>
          <input
            className="form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            type="file"
            accept="image/*"
            id="formFile"
            onChange={(e) => setFile([...(e.target.files as any)])}
          />
        </div>
        <div>
          <button
            type="button"
            onClick={handleSubmitFeedback}
            className="flex justify-center h-10 px-4 py-2 mt-2 text-sm font-medium text-white border border-transparent rounded-md max-w-max bg-primary-pink hover:bg-primary-bolder hover:border-primary-bolder"
          >
            Gửi
          </button>
        </div>
      </div>
    </LoadingContainer>
  );
};

export const Feedback = (): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);

  const [token, setToken] = useState<string>();

  return (
    <div className="fixed right-0 z-30 flex flex-col space-y-2 shadow-md bottom-20">
      {show ? (
        <div className="flex flex-col px-4 py-6 space-y-2 bg-white rounded-l-md">
          <GoogleReCaptchaProvider
            reCaptchaKey={SITE_KEY ?? ""}
            scriptProps={{
              async: false, // optional, default to false,
              defer: false, // optional, default to false
              appendTo: "head", // optional, default to "head", can be "head" or "body",
              nonce: undefined, // optional, default undefined
            }}
          >
            <>
              <GoogleReCaptcha onVerify={(token) => setToken(token)} />
              <div
                className="absolute cursor-pointer right-5 top-5"
                onClick={() => setShow(false)}
              >
                <XIcon className="w-6 h-6 text-primary-pink" />
              </div>
              <div className="pb-2 text-xl font-semibold border-b-2 border-gray-200 text-primary-pink">
                Góp ý trải nghiệm
              </div>
              <FeedbackForm token={token} />
            </>
          </GoogleReCaptchaProvider>
        </div>
      ) : (
        <div
          className="px-2 py-1 text-xl text-white cursor-pointer rounded-l-md bg-primary-pink"
          onClick={() => setShow(true)}
        >
          Feedback
        </div>
      )}
    </div>
  );
};
