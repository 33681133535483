/* eslint-disable jsx-a11y/alt-text */
import Image from "next/image";
import { useRouter } from "next/router";

/* eslint-disable @next/next/no-img-element */
const Footer = (): JSX.Element => {
  const router = useRouter();
  return (
    <div
      style={{ background: "#1B1B1B" }}
      className="flex pt-10 pb-4 text-white"
    >
      <div className="flex flex-col w-full m-auto max-w-7xl xl:px-4 lg:px-10">
        <div className="flex mb-8 rounded-xl">
          <img
            className="w-auto h-14 rounded-xl"
            src="/images/lh1t-full-logo.webp"
            alt="Workflow"
          />
        </div>
        <div className="flex flex-row justify-between">
          <div className="flex flex-col space-y-4 text-smoke">
            <div className="mb-4">
              <span>HỖ TRỢ HỌC VIÊN</span>
            </div>
            <span
              className="cursor-pointer hover:text-white"
              onClick={() => router.push("/about-us")}
            >
              Về chúng tôi
            </span>
            <span
              className="cursor-pointer hover:text-white"
              onClick={() => router.push("/qna")}
            >
              Câu hỏi thường gặp
            </span>
            {/* <span>Hướng dẫn sử dụng</span> */}
            <span>Hotline: 0932775247 (Phan Khắc Huy)</span>
            <span>Email: echoingdrum@gmail.com</span>
          </div>
          <div className="flex flex-col space-y-4 text-smoke">
            <div className="mb-4">
              <span>ĐỐI TÁC</span>
            </div>
            <div className="grid w-40 grid-cols-3 gap-5">
              <div>
                <Image
                  src="/images/landing-pages/logo/logo-coiviet.webp"
                  layout="responsive"
                  width={40}
                  height={40}
                  alt="partner-logo-coiviet"
                />
              </div>
              <div>
                <Image
                  src="/images/landing-pages/logo/logo-ccd.webp"
                  layout="responsive"
                  width={50}
                  height={50}
                  alt="partner-logo-ccd"
                />
              </div>
              <div>
                <Image
                  src="/images/landing-pages/logo/logo-magiskator.webp"
                  layout="responsive"
                  width={40}
                  height={40}
                  alt="partner-logo-magiskator"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-4 cursor-pointer text-smoke">
            <div className="mb-4">
              <span
                className="uppercase"
                onClick={() => router.push("/payment-method")}
              >
                Phương Thức Nạp Tô
              </span>
            </div>
          </div>
          <div className="flex flex-col space-y-4 text-smoke">
            <div className="mb-4">
              <span>KẾT NỐI VỚI CHÚNG TÔI</span>
            </div>
            <div className="flex flex-row space-x-6">
              <a
                href="https://www.facebook.com/LopHoc1To"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-10 h-10"
                  src="/images/facebook.webp"
                  alt="facebook"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCLisvuCFmUge4n-RZHu_qbQ"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-10 h-10"
                  src="/images/youtube.webp"
                  alt="youtube"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="w-full h-1 my-4 border-b border-smoke" />
        <div className="flex flex-col items-center text-smoke">
          <span>
            CÔNG TY TNHH VANG VỌNG TRỐNG CHẦU -- ECHOING DRUM COMPANY LIMITED
          </span>
          <span>MST: 0316240939 -- quản lý bởi chi cục Thuế Quận 6</span>
          <span>
            Địa chỉ: 131/9 Đường số 10, Phường 13, Quận 6, TP Hồ Chí Minh
          </span>
          <span className="mt-4">
            Copyright © lh1t 2022. All Rights Reserved
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
