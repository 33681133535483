import { Dialog, Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import axios from "axios";
import { LoadingContainer } from "components/LoadingContainer";
import { notify } from "components/Notification";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { mixpanel, sendServerAnalytics } from "services/analytics";
import { sendGaEvent } from "services/ga";

const RegistrationForm = ({ setView, setLoading }: any): JSX.Element => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");

  const [registrationView, setRegistrationView] = useState<"form" | "complete">(
    "form"
  );

  const handleRegister = async (e: any) => {
    e.preventDefault();
    setLoading?.(true);
    try {
      if (password !== repeatPassword) {
        notify({
          content: "Mật khẩu chưa khớp",
        });

        return;
      }
      const data = {
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: phone,
        password,
      };

      await axios.post("/api/v1/register", data);
      setRegistrationView("complete");
      mixpanel.track("Sign up");
      sendGaEvent({
        action: "signup",
        params: {
          email,
        },
      });

      sendServerAnalytics("do-sign-up", "sign-up", { email });
    } catch (error) {
      notify({
        content: "Đăng ký không thành công, xin hãy thử lại",
      });
    }
    setLoading?.(false);
  };
  return registrationView === "form" ? (
    <>
      <form onSubmit={handleRegister} className="space-y-6">
        <div className="flex flex-row w-full space-x-3">
          <div className="flex-1">
            <label htmlFor="mobile" className="sr-only">
              Họ
            </label>
            <input
              type="text"
              name="Họ"
              id="first_name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              autoComplete="name"
              placeholder="Họ"
              required
              className="block w-full h-10 py-2 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:border-primary-pink focus:ring-0 sm:text-sm"
            />
          </div>
          <div className="flex-1">
            <label htmlFor="last_name" className="sr-only">
              Tên
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              autoComplete="last_name"
              placeholder="Tên"
              required
              className="block w-full h-10 py-2 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:border-primary-pink focus:ring-0 sm:text-sm"
            />
          </div>
        </div>
        <div>
          <label htmlFor="mobile" className="sr-only">
            Số điện thoại
          </label>
          <input
            type="text"
            name="mobile"
            id="mobile"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            autoComplete="phone"
            placeholder="Số điện thoại"
            required
            className="block w-full h-10 py-2 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:border-primary-pink focus:ring-0 sm:text-sm"
          />
        </div>
        <div>
          <label htmlFor="email" className="sr-only">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            placeholder="Email"
            required
            className="block w-full h-10 py-2 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:border-primary-pink focus:ring-0 sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor="password" className="sr-only">
            Mật khẩu
          </label>
          <input
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Mật khẩu"
            autoComplete="current-password"
            required
            className="block w-full h-10 py-2 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:border-primary-pink focus:ring-0 sm:text-sm"
          />
        </div>
        <div>
          <label htmlFor="repeat-password" className="sr-only">
            Nhập lại mật khẩu
          </label>
          <input
            id="repeat-password"
            name="repeat-password"
            type="password"
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
            placeholder="Nhập lại mật khẩu"
            autoComplete="current-repeat-password"
            required
            className="block w-full h-10 py-2 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:border-primary-pink focus:ring-0 sm:text-sm"
          />
        </div>

        <div>
          <button
            type="submit"
            className="flex justify-center w-full h-10 px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-primary-pink hover:bg-primary-bolder hover:border-primary-bolder"
          >
            Đăng ký
          </button>
        </div>
      </form>
      <div className="mt-4">
        Bạn đã có tài khoản?{" "}
        <span
          className="font-semibold cursor-pointer text-primary-pink hover:underline"
          onClick={() => setView("login")}
        >
          Đăng nhập
        </span>
      </div>
    </>
  ) : (
    <div className="flex flex-col items-center p-5 space-y-6">
      <div className="text-center">
        <CheckCircleIcon className="w-12 h-12 text-green-700" />
      </div>
      <div className="font-semibold text-center text-gray-800">
        Đăng ký thành công, hãy xác nhận email để nhận được 3 tô miễn phí
      </div>
      <div
        className="text-lg font-semibold cursor-pointer text-primary-pink hover:text-blue-700"
        onClick={() => setView("login")}
      >
        Đăng nhập
      </div>
    </div>
  );
};

const LoginForm = ({ setLoading, setView }: any): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleLoginSubmit = async (e: any) => {
    setLoading(true);
    try {
      e.preventDefault();
      await axios.post(
        "/api/v1/login",
        {
          email,
          password,
        },
        {
          withCredentials: true,
        }
      );

      notify({
        content: "Đăng nhập thành công",
      });
      mixpanel.track("Login");
      sendGaEvent({
        action: "login",
        params: {
          email,
        },
      });
      // dispatch(userSagaActions.getUser());
      // closeModal();
      await sendServerAnalytics("do-login", "login", { email });
      window.location.reload();
    } catch (error) {
      notify({
        content: "Đăng nhập thất bại, hãy thử lại",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <form onSubmit={handleLoginSubmit} className="space-y-6">
        <div>
          <div className="grid grid-cols-2 gap-3 mt-4">
            <div className="">
              <a
                href="#"
                className="inline-flex justify-center w-full h-10 px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50 hover:text-primary-pink"
              >
                <span className="sr-only">Facebook</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </div>

            <div>
              <a
                href="#"
                className="inline-flex justify-center w-full h-10 px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50 hover:text-primary-pink"
              >
                <span className="sr-only">Google</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032 s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2 C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="relative mt-6">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 text-gray-500 bg-white">Hoặc</span>
          </div>
        </div>
        <div>
          <label htmlFor="mobile-or-email" className="sr-only">
            Số điện thoại hoặc email
          </label>
          <input
            type="text"
            name="mobile-or-email"
            id="mobile-or-email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            placeholder="Số điện thoại hoặc email"
            required
            className="block w-full h-10 py-2 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:border-primary-pink focus:ring-0 sm:text-sm"
          />
        </div>

        <div>
          <label htmlFor="password" className="sr-only">
            Mật khẩu
          </label>
          <input
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Mật khẩu"
            autoComplete="current-password"
            required
            className="block w-full h-10 py-2 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:border-primary-pink focus:ring-0 sm:text-sm"
          />
          <div
            className="mt-2 text-sm cursor-pointer text-primary-pink"
            onClick={() => setView("forget_password")}
          >
            Quên mật khẩu?
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="flex justify-center w-full h-10 px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-primary-pink hover:bg-primary-bolder hover:border-primary-bolder"
          >
            Đăng nhập
          </button>
        </div>
      </form>
      <div className="mt-4">
        Bạn chưa có tài khoản?{" "}
        <span
          className="font-semibold cursor-pointer text-primary-pink hover:underline"
          onClick={() => setView("register")}
        >
          Đăng ký
        </span>
      </div>
    </>
  );
};

const ForgetPassword = ({ setLoading, setView }: any): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [done, setDone] = useState<boolean>(false);

  const handleLoginSubmit = async (e: any) => {
    setLoading(true);
    try {
      e.preventDefault();
      await axios.post(
        "/api/v1/forget-password",
        {
          email,
        },
        {
          withCredentials: true,
        }
      );

      setDone(true);

      // dispatch(userSagaActions.getUser());
      // closeModal();
      // window.location.reload();
    } catch (error) {
      notify({
        content: "Thất bại, hãy thử lại",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    setDone(false);
  }, []);

  return !done ? (
    <>
      <form onSubmit={handleLoginSubmit} className="space-y-6">
        <div>
          <label htmlFor="mobile-or-email" className="sr-only">
            Email
          </label>
          <input
            type="text"
            name="mobile-or-email"
            id="mobile-or-email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            placeholder="Số điện thoại hoặc email"
            required
            className="block w-full h-10 py-2 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:border-primary-pink focus:ring-0 sm:text-sm"
          />
        </div>

        <div>
          <button
            type="submit"
            className="flex justify-center w-full h-10 px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-primary-pink hover:bg-primary-bolder hover:border-primary-bolder"
          >
            Gửi email
          </button>
        </div>
      </form>
      <div className="mt-4 text-sm">
        Hoặc quay lại{" "}
        <span
          className="font-semibold cursor-pointer text-primary-pink hover:underline"
          onClick={() => setView("login")}
        >
          Đăng nhập
        </span>
      </div>
    </>
  ) : (
    <>
      <div className="flex flex-col w-full space-y-4 text-center">
        <div>Hãy kiểm tra email để thực hiện đổi password</div>
      </div>
      <div className="mt-4 text-sm text-center">
        Quay lại{" "}
        <span
          className="font-semibold cursor-pointer text-primary-pink hover:underline"
          onClick={() => setView("login")}
        >
          Đăng nhập
        </span>
      </div>
    </>
  );
};

export const LoginModal = ({ isOpen, setIsOpen }: any): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [view, setView] = useState<"login" | "register" | "forget_password">(
    "login"
  );

  const closeModal = () => {
    setIsOpen(false);
  };

  const dispatch = useDispatch();

  const formName = useMemo(() => {
    if (view === "login") {
      return "Đăng nhập";
    }

    if (view === "register") {
      return "Đăng ký";
    }

    return "Quên mật khẩu";
  }, [view]);

  return (
    <LoadingContainer spinning={loading} full={true}>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-50" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <LoadingContainer spinning={loading} full={true}>
                  <div className="p-6">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {formName}
                    </Dialog.Title>
                    <div className="mt-8">
                      {view === "login" && (
                        <LoginForm
                          dispatch={dispatch}
                          setLoading={setLoading}
                          closeModal={closeModal}
                          setView={setView}
                        />
                      )}
                      {view === "register" && (
                        <RegistrationForm
                          setView={setView}
                          setLoading={setLoading}
                        />
                      )}
                      {view === "forget_password" && (
                        <ForgetPassword
                          setView={setView}
                          setLoading={setLoading}
                        />
                      )}
                    </div>
                  </div>
                </LoadingContainer>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </LoadingContainer>
  );
};
