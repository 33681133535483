import { Spinner } from "../Spinner";
import classnames from "classnames";

interface LoadingContainerProps {
  spinning: boolean;
  full?: boolean;
  children: JSX.Element;
  size?: number;
  text?: string;
}
const LoadingContainer = (props: LoadingContainerProps): JSX.Element => {
  return (
    <div className="relative" style={{ width: "100%", height: "100%" }}>
      {props?.spinning && (
        <div
          className={classnames({
            "absolute z-50 overflow-hidden opacity-75 flex flex-col items-center justify-center bg-opacity-10 bg-black":
              props?.spinning,
            "w-full h-full": props?.full,
          })}
        >
          <div className="flex flex-col items-center gap-1">
            <div>
              <Spinner size={props.size ?? 4} />
            </div>
            {props.text && (
              <div className="text-lg font-normal">{props.text}</div>
            )}
          </div>
        </div>
      )}

      {props.children}
      {/* {!props.spinning && props.children} */}
    </div>
  );
};

export { LoadingContainer };
