import { Transition, Dialog } from "@headlessui/react";
import axios from "axios";
import { LoadingContainer } from "components/LoadingContainer";
import { notify } from "components/Notification";
import { useState, Fragment } from "react";

export const VoucherModal = ({ isOpen, setIsOpen }: any): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [voucherCode, setVoucherCode] = useState<string>("");

  const closeModal = () => setIsOpen(false);

  const applyVoucher = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        "/api/v1/voucher/use",
        {
          code: voucherCode,
        },
        {
          withCredentials: true,
        }
      );

      console.log("use voucher response", res.data);

      notify({
        content: "Sử dụng voucher thành công, chúc bạn học vui",
      });

      setIsOpen(false);
    } catch (error: any) {
      console.log({ error });
      const message =
        error.response?.data?.data?.message ?? "Đã có lỗi xảy ra, hãy thử lại.";

      notify({
        content: message,
      });
    }

    setLoading(false);
  };

  return (
    <LoadingContainer spinning={loading} full={true}>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-50" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <LoadingContainer spinning={loading} full={true}>
                  <div className="p-6">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Voucher
                    </Dialog.Title>
                    <div className="flex flex-col gap-4 mt-8">
                      <div>Hãy nhập mã voucher của bạn</div>
                      <div>
                        <input
                          type="text"
                          name="mobile-or-email"
                          id="mobile-or-email"
                          value={voucherCode}
                          onChange={(e) => setVoucherCode(e.target.value)}
                          placeholder="Voucher Code"
                          required
                          className="block w-full h-10 py-2 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:border-primary-pink focus:ring-0 sm:text-sm"
                        />
                      </div>

                      <div className="mt-4">
                        <button
                          type="button"
                          className="flex justify-center w-full h-10 px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-primary-pink hover:bg-primary-bolder hover:border-primary-bolder"
                          onClick={applyVoucher}
                        >
                          Sử dụng voucher
                        </button>
                      </div>
                    </div>
                  </div>
                </LoadingContainer>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </LoadingContainer>
  );
};
