import React from "react";
import Head from "next/head";

export const CommonMeta = () => (
  <Head>
    {/* <!-- Android  --> */}
    <meta name="theme-color" content="#c03e72" />
    <meta name="mobile-web-app-capable" content="yes" />

    {/* <!-- iOS --> */}
    <meta name="apple-mobile-web-app-title" content="Lớp học 1 tô" />
    <meta name="apple-mobile-web-app-capable" content="yes" />
    <meta name="apple-mobile-web-app-status-bar-style" content="default" />

    {/* <!-- Windows  --> */}
    <meta name="msapplication-navbutton-color" content="#c03e72" />
    <meta name="msapplication-TileColor" content="#c03e72" />
    <meta name="msapplication-TileImage" content="ms-icon-144x144.png" />
    <meta name="msapplication-config" content="browserconfig.xml" />

    {/* <!-- Pinned Sites  --> */}
    <meta name="application-name" content="Lớp học 1 tô" />
    <meta name="msapplication-tooltip" content="Tooltip Text" />
    <meta name="msapplication-starturl" content="/" />

    {/* <!-- Tap highlighting  --> */}
    <meta name="msapplication-tap-highlight" content="no" />

    {/* <!-- UC Mobile Browser  --> */}
    <meta name="full-screen" content="yes" />
    <meta name="browsermode" content="application" />

    {/* <!-- Disable night mode for this page  --> */}
    {/* <meta name="nightmode" content="enable/disable" /> */}

    {/* <!-- Layout mode --> */}
    <meta name="layoutmode" content="fitscreen/standard" />

    {/* <!-- imagemode - show image even in text only mode  --> */}
    <meta name="imagemode" content="force" />

    {/* <!-- Orientation  --> */}
    <meta name="screen-orientation" content="portrait"></meta>
    <link rel="shortcut icon" href="/favicon.ico"></link>
    <meta
      name="google-site-verification"
      //   content="4hXnCfhCeEUMru4tj04GQoV_8KxsyOg0wHId_mW-yJU"
    />
  </Head>
);

const getTitle = (props: any) => {
  if (props.friendlyTitle) {
    return "- " + props.friendlyTitle;
  }

  if (props.metaTitle) {
    return "- " + props.metaTitle;
  }

  if (props.title) {
    return "- " + props.title;
  }

  if (props.name) {
    return "- " + props.name;
  }

  return "";
};

export const HeaderMeta = (props: any) => {
  return (
    <Head>
      <meta charSet="utf-8" />
      {/* <meta http-equiv=" X-UA-Compatible" content="IE=edge" /> */}
      <meta name="viewport" content="width=device-width" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, viewport-fit=cover"
      />
      <title>{`Lớp học 1 tô ${getTitle(props)}`}</title>
      <meta
        name="title"
        content={props?.metaTitle ?? "Lớp học 1 tô - Nơi bắt đầu việc học!"}
      />
      <meta
        name="description"
        content={props?.metaDescription || "Lớp học 1 tô - mô tả"}
      />
      <meta name="keywords" content={props?.metaKeywords} />

      {/* <!-- Meta Tags / Facebook  --> */}
      <meta property="og:type" content="website" />
      {/* <meta property="og:url" content="" /> */}
      <meta
        property="og:title"
        content={
          props?.sharingMetaTitle ?? "Lớp học 1 tô - Nơi bắt đầu việc học"
        }
      />
      <meta
        property="og:description"
        content={
          props?.sharingMetaDesc ?? "Lớp học 1 tô - Nơi bắt đầu việc học"
        }
      />
      <meta
        property="og:image"
        content={props.image ?? "/images/logo-vuong.webp"}
      />
    </Head>
  );
};

export default HeaderMeta;
