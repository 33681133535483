/* eslint-disable @next/next/no-img-element */
import { Fragment, useEffect, useState } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import {
  ArrowNarrowLeftIcon,
  BookOpenIcon,
  HomeIcon,
  SearchIcon,
  TicketIcon,
} from "@heroicons/react/solid";
import { useDispatch } from "react-redux";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { Spinner } from "components/Spinner";
import { useSelector } from "react-redux";
import { IStore } from "stores/types";
import { LoginModal } from "./LoginModal";
import { userActions } from "stores/user/user.reducer";
import { CommonMeta } from "./SeoHeader";
import axios from "axios";
import Footer from "components/Footer";
import { Feedback } from "./Feedback";
import Link from "next/link";
import { sendGaEvent } from "services/ga";
import { requestFirebaseNotificationPermission } from "services/firebase";
import { KhietBongStage } from "./KhietBongStage";
import { VoucherModal } from "./VoucherModal";

const user = {
  name: "Whitney Francis",
  email: "whitney@example.com",
  imageUrl: "/images/logo-echoing-drum.png",
};
const navigation = [
  { name: "Trang chủ", href: "/" },
  { name: "Bài học", href: "/courses" },
  // { name: "Lớp học của tôi", href: "#" },
  { name: "Chủ đề quan tâm", href: "#" },
  // { name: "Hỏi đáp", href: "#" },
];

const userNavigation = [
  { name: "Thông tin tài khoản", href: "/user?part=profile" },
  { name: "Mua tô", href: "/payment-method" },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

interface Breadcrumb {
  name: string;
  href: string;
  current?: boolean;
}

interface LayoutProps {
  children: JSX.Element;
  breadcrumb?: Breadcrumb[];
}

const Breadcrumbs = ({ breadcrumb }: { breadcrumb: Breadcrumb[] }) => {
  return (
    <div className="mx-auto max-w-7xl xl:px-4 lg:px-10">
      <div className="py-3 border-t border-gray-200">
        <nav className="flex" aria-label="Breadcrumb">
          <div className="flex sm:hidden">
            <a
              href="#"
              className="inline-flex space-x-3 text-sm font-medium text-gray-500 group hover:text-gray-700"
            >
              <ArrowNarrowLeftIcon
                className="flex-shrink-0 w-5 h-5 text-gray-400 group-hover:text-gray-600"
                aria-hidden="true"
              />
              <span>Back to Applicants</span>
            </a>
          </div>
          <div className="hidden sm:block">
            <ol role="list" className="flex items-center space-x-4">
              <li>
                <div>
                  <Link href="/">
                    <span>
                      <HomeIcon
                        className="flex-shrink-0 w-5 h-5 text-gray-400 hover:text-gray-500"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Home</span>
                    </span>
                  </Link>
                </div>
              </li>
              {breadcrumb.map((item) => (
                <li key={item.name}>
                  <div className="flex items-center">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-gray-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                    <a
                      href={item.href}
                      className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </a>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </nav>
      </div>
    </div>
  );
};

const HeaderUserMenu = (): JSX.Element => {
  const { user, loginModalOpen, voucherModelOpen } = useSelector(
    (store: IStore) => store.user
  );
  // const [loginModelOpen, setLoginModelOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      await axios.get("/api/v1/logout", {
        withCredentials: true,
      });
      dispatch(userActions.setUser(null));
    } catch (error) {}
  };

  const setLoginModelOpen = (isOpen: boolean) => {
    dispatch(userActions.setLoginModalOpen(isOpen));
  };

  const setVoucherModelOpen = (isOpen: boolean) => {
    dispatch(userActions.setVoucherModalOpen(isOpen));
  };

  return (
    <Menu as="div" className="relative flex-shrink-0 ml-4">
      <div>
        {loginModalOpen && (
          <LoginModal isOpen={loginModalOpen} setIsOpen={setLoginModelOpen} />
        )}
        {voucherModelOpen && (
          <VoucherModal
            isOpen={voucherModelOpen}
            setIsOpen={setVoucherModelOpen}
          />
        )}
        {user ? (
          <Menu.Button className="flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            <span className="sr-only">Open user menu</span>
            <img
              className="w-8 h-8 rounded-full"
              // src={user.avatar ?? "/images/logo-echoing-drum.png"}
              src={user.avatar ?? "/images/logo-vuong.webp"}
              alt=""
            />
            {/* {user.first_name} */}
          </Menu.Button>
        ) : (
          <button
            className="h-10 bg-white btn no-animation btn-sm border-primary-pink text-primary-pink hover:bg-primary-pink hover:border-primary-pink hover:text-white"
            onClick={() => {
              sendGaEvent({
                action: "click-login-button",
                params: {},
              });
              setLoginModelOpen(true);
            }}
          >
            Đăng nhập
          </button>
        )}
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-20 w-56 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {() => (
              <div className="flex flex-col px-4 py-4 space-x-1 border-b">
                <div>
                  {user?.first_name ?? ""} {user?.last_name ?? ""}
                </div>
                <div className="text-sm text-gray-700">{user?.email ?? ""}</div>
                <div className="flex flex-row justify-between mt-3 text-gray-700">
                  <div className="flex flex-row items-center space-x-1">
                    <BookOpenIcon className="w-5 h-5" />
                    <div className="text-sm ">Mẫu giáo</div>
                  </div>
                  <div className="flex flex-row items-center space-x-1">
                    <TicketIcon className="w-5 h-5" />
                    <div className="text-sm ">{user?.credit_amount} tô</div>
                  </div>
                </div>
              </div>
            )}
          </Menu.Item>
          {userNavigation.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <a
                  href={item.href}
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700"
                  )}
                >
                  {item.name}
                </a>
              )}
            </Menu.Item>
          ))}
          <Menu.Item key={"admin"}>
            {({ active }) => (
              <div
                className={classNames(
                  active ? "bg-gray-100" : "",
                  "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                )}
                onClick={() => {
                  setVoucherModelOpen(true);
                }}
              >
                Sử dụng Voucher
              </div>
            )}
          </Menu.Item>
          {user?.is_admin && (
            <Menu.Item key={"admin"}>
              {({ active }) => (
                <div
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                  )}
                  onClick={() => {
                    window.location.href = "/admin";
                  }}
                >
                  Admin
                </div>
              )}
            </Menu.Item>
          )}
          <Menu.Item key={"logout"}>
            {({ active }) => (
              <div
                className={classNames(
                  active ? "bg-gray-100" : "",
                  "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                )}
                onClick={handleLogout}
              >
                Đăng xuất
              </div>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default function Layout(props: LayoutProps) {
  const [userLoading] = useState<boolean>(false);

  const handleGetToken = async () => {
    try {
      const token = await requestFirebaseNotificationPermission();
      console.log("Firebase token", {
        token,
      });
      if (token) {
        axios.post(
          "/api/noti/",
          {
            registration_id: token,
            type: "web",
          },
          {
            withCredentials: true,
          }
        );
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (process.browser) {
      handleGetToken();
    }
  }, []);

  return (
    <>
      <div className="flex flex-col items-center justify-center visible w-screen h-screen text-white md:hidden bg-primary-pink">
        <div className="flex justify-center mb-20">
          <img alt="mobile-ga" src="/images/logo-white.png" className="w-4/5" />
        </div>
        <div className="relative flex justify-center mt-16">
          <div>
            <img
              src="/images/bubble.png"
              alt="ga-khiet-bong"
              className="absolute w-52 -top-28 right-6"
              // height="auto"
            />
            <div className="absolute text-sm text-center w-36 text-primary-pink -top-20 right-12">
              Phiên bản mobile đang được phát triển, hãy theo dõi để được cập
              nhật nhé!!!
            </div>
          </div>
          <img
            alt="mobile-ga"
            src="/images/ga/gatiecnuoi.png"
            className="w-1/2 "
          />
        </div>
      </div>
      <div className="hidden md:block">
        <KhietBongStage />
        <CommonMeta />
        <Feedback />
        <div className="flex flex-col min-h-screen">
          <header className="bg-white shadow">
            <div className="mx-auto max-w-7xl xl:px-4 lg:px-10">
              <Popover className="flex justify-between h-16">
                <div className="flex px-2 lg:px-0">
                  <div className="flex items-center flex-shrink-0">
                    <Link href="/">
                      <img
                        className="w-auto h-14"
                        src="/images/lh1t-full-logo.webp"
                        alt="Logo Lớp học 1 tô"
                      />
                    </Link>
                  </div>
                  <nav
                    aria-label="Global"
                    className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-4"
                  >
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="px-3 py-2 text-sm font-medium text-gray-900"
                      >
                        {item.name}
                      </a>
                    ))}
                  </nav>
                </div>
                <div className="flex items-center justify-center flex-1 px-2 lg:ml-6 lg:justify-end">
                  <div className="w-full max-w-lg lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">
                      Tìm kiếm
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <SearchIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="block w-full h-10 py-2 pl-10 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:border-primary-pink focus:ring-0 sm:text-sm"
                        placeholder="Tìm kiếm"
                        type="search"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-center lg:hidden">
                  {/* Mobile menu button */}
                  <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
                <Transition.Root as={Fragment}>
                  <div className="lg:hidden">
                    <Transition.Child
                      as={Fragment}
                      enter="duration-150 ease-out"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="duration-150 ease-in"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Popover.Overlay
                        className="fixed inset-0 z-20 bg-black bg-opacity-25"
                        aria-hidden="true"
                      />
                    </Transition.Child>

                    <Transition.Child
                      as={Fragment}
                      enter="duration-150 ease-out"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="duration-150 ease-in"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Popover.Panel
                        focus
                        className="absolute top-0 right-0 z-30 w-full p-2 transition origin-top transform max-w-none"
                      >
                        <div className="bg-white divide-y divide-gray-200 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="pt-3 pb-2">
                            <div className="flex items-center justify-between px-4">
                              <div>
                                <img
                                  className="w-auto h-8"
                                  src="/images/logo-vuong.webp"
                                  alt="Workflow"
                                />
                              </div>
                              <div className="-mr-2">
                                <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                                  <span className="sr-only">Close menu</span>
                                  <XIcon
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                  />
                                </Popover.Button>
                              </div>
                            </div>
                            <div className="px-2 mt-3 space-y-1">
                              {navigation.map((item) => (
                                <a
                                  key={item.name}
                                  href={item.href}
                                  className="block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 hover:text-gray-800"
                                >
                                  {item.name}
                                </a>
                              ))}
                            </div>
                          </div>
                          <div className="pt-4 pb-2">
                            <div className="flex items-center px-5">
                              <div className="flex-shrink-0">
                                <img
                                  className="w-10 h-10 rounded-full"
                                  src={user.imageUrl}
                                  alt=""
                                />
                              </div>
                              <div className="ml-3">
                                <div className="text-base font-medium text-gray-800">
                                  {user.name}
                                </div>
                                <div className="text-sm font-medium text-gray-500">
                                  {user.email}
                                </div>
                              </div>
                              <button
                                type="button"
                                className="flex-shrink-0 p-1 ml-auto text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                              >
                                <span className="sr-only">
                                  View notifications
                                </span>
                                <BellIcon
                                  className="w-6 h-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                            <div className="px-2 mt-3 space-y-1">
                              {userNavigation.map((item) => (
                                <a
                                  key={item.name}
                                  href={item.href}
                                  className="block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 hover:text-gray-800"
                                >
                                  {item.name}
                                </a>
                              ))}
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition.Child>
                  </div>
                </Transition.Root>
                <div className="hidden lg:ml-4 lg:flex lg:items-center">
                  <button
                    type="button"
                    className="flex-shrink-0 p-1 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="w-6 h-6" aria-hidden="true" />
                  </button>

                  {/* Profile dropdown */}

                  {userLoading ? <Spinner /> : <HeaderUserMenu />}
                </div>
              </Popover>
            </div>
            {props.breadcrumb && <Breadcrumbs breadcrumb={props.breadcrumb} />}
          </header>

          <main className="flex-1 h-full pt-10 bg-gray-100">
            {props.children}
          </main>

          <Footer />
        </div>
      </div>
    </>
  );
}
