import { axiosInstance } from "services/apiClient";
import { wrapper } from "stores";
import { userActions } from "stores/user/user.reducer";

const withUser = (Page: any) => {
  const Wrapper = (props: any) => <Page {...props} />;
  Wrapper.getInitialProps = wrapper.getInitialPageProps(
    (store) => async (ctx: any) => {
      console.log("get user in backend");
      try {
        if (!process.browser) {
          console.log("In backend");
          const res = await axiosInstance.get("/user", {
            headers: {
              cookie: ctx.req?.headers?.cookie ?? "",
            },
          });

          store.dispatch(userActions.setUser(res.data.data ?? {}));
        }
      } catch (error) {
        console.log({ error });
      }

      //   return {};

      let rs: any = {};
      if (Page.getInitialProps) {
        rs = await Page.getInitialProps({
          ...ctx,
          store,
        });
      }
      return rs;
    }
  );

  return Wrapper;
};

export default withUser;
