import { XIcon } from "@heroicons/react/outline";
import Notification from "rc-notification";
// import './style.scss';

let notification: any = null;
if (process.browser) {
  Notification.newInstance(
    {
      maxCount: 5,
      style: {
        right: 60,
        top: 24,
        bottom: "auto",
      },
    },
    (n) => {
      notification = n;
    }
  );
}

interface NotificationParams {
  content: string | Node;
}

export const notify = (params: NotificationParams) => {
  notification?.notice?.({
    duration: 3,
    content: params.content,
    closeIcon: <XIcon className="w-4 h-4 text-black" />,
    closable: true,
    onClose() {
      console.log("simple close");
    },
  });
};
